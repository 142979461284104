.auth {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 70px;
    font-family: Cafe24Oneprettynight, sans-serif;
}

.auth h2 {
    font-size: 40px;
}

.auth form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
    margin-bottom: 10%;
    background: linear-gradient(to bottom, #84cefd, #ffa6fd);
}

.auth form label {
    padding: 10px;
}

.auth form input {
    padding: 0;
    font-family: Cafe24Oneprettynight, sans-serif;
    font-size: 18px;
    margin-right: 10px;
}

.auth form input[type=password] {
    color: black;
}

.auth form button[type=submit] {
    width: 6em;
    height: 2.5em;
    margin-right: 0;
    margin-left: auto;
    font-size: 20px;
}

.auth form button[type=button] {
    width: 5em;
    height: 2em;
    font-size: 14px;
}

.auth button.backToLogin {
    width: 6em;
    height: 2.5em;
    font-size: 20px;
}

.auth form .container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
}

.auth form .container .inputEntry {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    height: 2.5em;
}

.auth form .container .inputEntry .iconWrapper {
    height: calc(100%);
    aspect-ratio: 1 / 1;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth form .container .inputEntry .iconWrapper svg {
    color: black;
    font-size: 20px;
}

.auth form .container .inputEntry input {
    width: 100%;
}

.auth form .container a {
    color: #ffa6fd;
    text-decoration: none;
    font-size: 14px;
}

.auth form .container a:hover {
    color: #84cefd;
}

.auth form .container .bottomContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    align-items: center;
}

.auth form .container:nth-child(2) {
    background-color: rgba(30%, 30%, 30%, 50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1040px) {
    .auth form {
        width: 60%;
    }
}

@media screen and (max-width: 700px) {
    .auth form {
        width: 95%;
    }
}

@media screen and (max-width: 530px) {
    .auth form {
        width: 100%;
    }
}