.notFound {
    color: white;
    font-family: Cafe24Oneprettynight, sans-serif;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 200px;
}

.notFound h1 {
    font-size: 80px;
}

.notFound p {
    font-size: 18px;
}