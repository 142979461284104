:root {
    --header-color: #000000;
    --text-hovered-color: #44aada;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--header-color);
    padding: 8px 12px;
}

.top img {
    width: 91px;
    height: 60px;
    padding: 0 20px;
}

.top button {
    display: none;
}

.header a {
    text-decoration: none;
    color: white;
    font-family: Cafe24Oneprettynight, sans-serif;
}

.menuContainer {
    display: flex;
    width: 100%;
    height: 100%;
}

.menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.navbar, .navbtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding-left: 0;
    list-style: none;
}

.navbar {
    width: 400px;
    padding: 0;
}

.navbar a {
    padding: 10px 70px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    transition: 0.4s;
}

.navbar a:hover {
    background-color: #444444;
    border-radius: 3px;
    color: var(--text-hovered-color);
}

.navbtn {
    justify-content: space-between;
    width: 200px;
    max-width: 350px;
    padding: 0 40px;
}

.navbtn a {
    padding: 10px 15px;
    width: 20px;
    font-size: 14px;
    transition: 0.4s;
}

.navbtn a:hover {
    color: var(--text-hovered-color);
}

@media screen and (max-width: 986px) {
    .navbar {
        width: 200px;
        padding: 10px 10px;
    }

    .navbar a {
        padding: 10px 30px;
    }

    .navbtn {
        width: 150px;
        padding: 0;
    }

    .navbtn a {
        padding: 10px 5px;
    }
}

@media screen and (max-width: 700px) {
    .header, .menu {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .top {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .top img {
    }

    .top button {
        display: flex;
        background-color: black;
        padding: 0;
        border: none;
        width: 36px;
        height: 39px;
        position: absolute;
        margin-top: 15px;
        right: 3%;
        cursor: pointer;
        transition: 0.3s;
    }

    .top button:hover {
        transform: scale(120%) translateY(5%);
    }

    .top button:active {
        transform: scale(80%) translateY(-5%);
    }

    .menu {
        display: flex;
        position: relative;
        overflow: hidden;
        transition: 0.3s;
    }

    /* fade in */

    @keyframes slide-fade-in-dropdown-animation {
        0% {
            transform: translateY(-0%);
            height: 0;
        }

        100% {
            transform: translateY(0);
            height: 8em;
        }
    }

    .slide-fade-in-dropdown {
        overflow: hidden;
    }

    .slide-fade-in-dropdown > div {
        animation: slide-fade-in-dropdown-animation .4s ease;
    }

    /* fade out */

    @keyframes slide-fade-out-dropdown-animation {
        0% {
            transform: translateY(0);
            height: 8em;
        }

        100% {
            transform: translateY(-0%);
            height: 0;
        }
    }

    .slide-fade-out-dropdown {
        overflow: hidden;
    }

    .slide-fade-out-dropdown > div {
        animation: slide-fade-out-dropdown-animation .4s ease;
        animation-fill-mode: forwards;
    }

    .menu.active {
        display: flex;
        position: relative;
        transform: translateY(0%);
    }

    .navbar {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .navbar li {
        width: 100%;
    }

    .navbar a {
        padding: 10px 0;
        width: 100%;
        text-align: center;
        display: inline-block;
    }

    .navbtn {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 10px 0;
    }
}

@media screen and (max-width: 450px) {
    .top img {
        height: 50px;
    }
}