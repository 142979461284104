body {
    background-color: black;
}

hr.separator {
    width: 97%;
    height: 1px;
    background-color: white;
    border: 0;
}

button.customButton {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: Cafe24Oneprettynight, sans-serif;
    border: none;
    border-radius: 4px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: 0.3s;
    background: white;
}

button.customButton:active, button.customButton:hover, button.customButton:focus {
    background: #dddddd;
    outline: 0;
}

button.customButton:disabled {
    opacity: 0.5;
}

@font-face {
    font-family: 'Cafe24Oneprettynight';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Oneprettynight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
