.main {
    background-color: black;
    color: white;
    padding: 0;
}

.main hr.mainSeparator {
    background-color: #666666;
    width: 88%;
    height: 1px;
    border: 0;
}

#introduction {
    height: 600px;
}

#introduction canvas {
    position: absolute;
    left: 5%;
    margin-top: 10%;
    width: 70%;
}

#introduction .subtitle {
    position: absolute;
    left: 64%;
    margin-top: 25%;
    width: 350px;
}

#introduction .subtitle hr {
    background-color: white;
    border: 0;
    height: 1px;
    margin-bottom: 15px;
    margin-top: 15px;
}

#introduction .subtitle p.subtitle1 {
    text-align: center;
    font-family: 'Cafe24Oneprettynight', sans-serif;
    font-size: 32px;
    margin: 10px;
}

#introduction .subtitle p.example {
    text-align: center;
    font-family: 'Cafe24Oneprettynight', sans-serif;
    font-size: 20px;
    margin: 0;
}

#description {
    padding: 0 6%;
    font-family: Cafe24Oneprettynight, sans-serif;
}

#description h3 {
    font-size: 40px;
    margin-top: 40px;
    margin-bottom: 25px;
}

#description p.description1 {
    font-size: 20px;
}

#description ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
}

#description li {
    max-width: 40%;
}

#description li h5 {
    font-size: 25px;
}

@media screen and (max-width: 1250px) {
    #introduction .subtitle {
        margin-top: 30%;
        left: 50%;
    }
}

@media screen and (max-width: 1040px) {
    #introduction {
        height: 500px;
    }

    #introduction .subtitle {
        margin-top: 33%;
        left: 45%;
    }
}

@media screen and (max-width: 700px) {
    #introduction {
        height: 450px;
    }

    #introduction canvas {
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
    }

    #introduction .subtitle {
        left: 50%;
        transform: translateX(-50%);
    }

    #description ul {
        flex-direction: column;
    }

    #description li {
        max-width: 85%;
    }
}

@media screen and (max-width: 400px) {
    #introduction canvas {
        width: 90%;
    }

    #introduction .subtitle {
        width: 250px;
        margin-top: 40%;
    }

    #introduction .subtitle p.subtitle1 {
        font-size: 24px;
    }

    #introduction .subtitle p.example {
        font-size: 14px;
    }
}

