.footer {
    display: flex;
    background-color: black;
    padding: 12px 12px;
    justify-content: space-between;
}

.footer p {
    color: white;
    font-family: Cafe24Oneprettynight, sans-serif;
    font-size: 18px;
    margin: 0;
}

.footer p span {
    font-family: sans-serif;
    font-size: 18px;
}

.footer p a {
    color: white;
}
